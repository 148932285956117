// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-web-design-development-js": () => import("./../../../src/pages/web-design-development.js" /* webpackChunkName: "component---src-pages-web-design-development-js" */),
  "component---src-templates-portfolio-item-js": () => import("./../../../src/templates/portfolio-item.js" /* webpackChunkName: "component---src-templates-portfolio-item-js" */)
}

